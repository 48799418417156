// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.93.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.93.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.max-width-351-square {
  width: 100%;
  max-width: 351px;
  aspect-ratio: 1;
  border-radius: 30px;
}

.max-width-724 {
  width: 100%;
  max-width: 724px;
}`, "",{"version":3,"sources":["webpack://./src/LandingPage/Statics/statics.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,gBAAA;EACA,eAAA;EACA,mBAAA;AACF;;AAEA;EACE,WAAA;EACA,gBAAA;AACF","sourcesContent":[".max-width-351-square{\n  width: 100%;\n  max-width: 351px;\n  aspect-ratio: 1;\n  border-radius: 30px;\n}\n\n.max-width-724{\n  width: 100%;\n  max-width: 724px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
