// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.93.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.93.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (min-width: 1280px) {
  .india-map-size {
    width: 85%;
    height: auto;
  }
}
.india-map-size {
  width: 70%;
  height: auto;
}

.margin-top-negative-14 {
  margin-top: -14px;
}

g {
  fill: var(--default-fill-color, #003276);
  stroke: var(--stroke-color, #9CB3CF);
}
g > text {
  stroke: black;
  display: none;
}
g:hover {
  fill: var(--hover-fill-color, #FFFFFF);
}
.app {
  position: relative;
}

.chipv {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
  z-index: 5;
}

.chipv .chiptext {
  background-color: var(--chip-background-color, #000);
  color: var(--chip-text-color, #FFFFFF) !important;
  text-align: center;
  border-radius: 6px;
  padding: 0.5rem;
  position: absolute;
  z-index: 1;
  top: -5px;
  left: 110%;
}

.chipv .chiptext::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent black transparent transparent;
}`, "",{"version":3,"sources":["webpack://./src/LandingPage/Trends/IndiaMap/india-map.scss"],"names":[],"mappings":"AACA;EACE;IAEE,UAAA;IACA,YAAA;EADF;AACF;AAKA;EAEI,UAAA;EACA,YAAA;AAJJ;;AASA;EACE,iBAAA;AANF;;AAUA;EACE,wCAAA;EACA,oCAAA;AAPF;AAQE;EACE,aAAA;EACA,aAAA;AANJ;AAQE;EACE,sCAAA;AANJ;AAcA;EACE,kBAAA;AAZF;;AAeA;EACE,kBAAA;EACA,qBAAA;EACA,+BAAA;EACA,UAAA;AAZF;;AAeA;EAGE,oDAAA;EACA,iDAAA;EACA,kBAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,UAAA;EACA,SAAA;EACA,UAAA;AAdF;;AAiBA;EACE,WAAA;EACA,kBAAA;EACA,QAAA;EACA,WAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;EACA,uDAAA;AAdF","sourcesContent":["\n@media (min-width: 1280px) {\n  .india-map-size{\n\n    width: 85%;\n    height: auto;\n\n  }\n}\n\n.india-map-size{\n\n    width: 70%;\n    height: auto;\n\n}\n\n\n.margin-top-negative-14 {\n  margin-top: -14px;\n}\n\n\ng {\n  fill: var(--default-fill-color, #003276);\n  stroke: var(--stroke-color, #9CB3CF);\n  > text {\n    stroke: black;\n    display: none;\n  }\n  &:hover {\n    fill: var(--hover-fill-color, #FFFFFF);\n\n    > text {\n      // stroke: black;\n      // display: block !important;\n    }\n  }\n}\n.app {\n  position: relative;\n}\n\n.chipv {\n  position: relative;\n  display: inline-block;\n  border-bottom: 1px dotted black;\n  z-index: 5;\n}\n\n.chipv .chiptext {\n  //@apply text-white text-sm font-normal leading-snug;\n  // min-width: 200px;\n  background-color: var(--chip-background-color, #000);\n  color: var(--chip-text-color, #FFFFFF) !important;\n  text-align: center;\n  border-radius: 6px;\n  padding: 0.5rem;\n  position: absolute;\n  z-index: 1;\n  top: -5px;\n  left: 110%;\n}\n\n.chipv .chiptext::after {\n  content: \"\";\n  position: absolute;\n  top: 50%;\n  right: 100%;\n  margin-top: -5px;\n  border-width: 5px;\n  border-style: solid;\n  border-color: transparent black transparent transparent;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
