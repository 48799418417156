import IndiaMap from "./IndiaMap/IndiaMap";
import {useState} from "react";
import "./trends.scss"
const Trends = ()=>{
    const [date,setDate] = useState<Date>(new Date('2024-08-24'))
    return(
        <section className="container section-padding">
            <div className="header-section text-center theme-primary">
                <h5>Trends Dashboard</h5>
                <h2 className="fw-bold">Concise <span className="theme-secondary">Insightful</span> Data</h2>
            </div>

            <div className="row">
                 <div className="col-lg-6 col-12">

                </div>
                <div className="col-lg-6 col-12 position-relative india-map-div">
                    <IndiaMap/>
                    <div className="india-map-absolute-div ">
                        <small>As {`${date.getDate()}/${date.toLocaleDateString('default', {month: 'numeric'})}/${date.getFullYear()}`}</small>
                        <p className="fw-bold text-mute">India Case Filing <br/> Dashboard</p>
                        <div><button className="btn btn-info text-white fw-bold">Show Full View</button></div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Trends