import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./carousel.scss"

const images = [
  {
    src: "/footerlogos/BIS2.svg",
    text: "BIS",
    url: "https://www.bis.gov.in/",
    alt: "BIS_LOGO"
  },
  {
    src: "/footerlogos/download.svg",
    text: "DoCA",
    url: "https://doca.gov.in/",
    alt: "DoCA_LOGO"
  },
  {
    src: "/footerlogos/jagograhakjaago.svg",
    text: "Jaago Grahal Jaago",
    url: "https://jagograhakjago.gov.in/",
    alt: "Jaago Grahal Jaago_LOGO"
  },
  {
    src: "/footerlogos/NTH.svg",
    text: "NTH",
    url: "https://www.nth.gov.in/",
    alt: "NTH_LOGO"
  },
  {
    src: "/footerlogos/NCCF.svg",
    text: "NCCF",
    url: "https://nccf-india.com/",
    alt: "NCCF_LOGO"
  },
  {
    src: "/footerlogos/download.svg",
    text: "NCDRC",
    url: "https://ncdrc.nic.in/",
    alt: "NCDRC_LOGO"
  },
  {
    src: "/footerlogos/NCH.svg",
    text: "NCH",
    url: "https://consumerhelpline.gov.in/",
    alt: "NCH_LOGO"
  },
  {
    src: "/footerlogos/download.svg",
    text: "OLMA",
    url: "https://lm.doca.gov.in/",
    alt: "OLMA_LOGO"
  },
  {
    src: "/footerlogos/CWF.svg",
    text: "CWF",
    url: "https://jagograhakjago.gov.in/cwf/Default.aspx",
    alt: "CWF_LOGO"
  },
];
export default function FooterCarousel() {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 6
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 4
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 3
    }
  };
  const handleClick = (url: string) => {
    if (window.confirm("You are being redirected to an external website. Please note that India Portal is not responsible for external websites content & privacy policies.")
    ) {
      window.open(url, "_blank");
    }
  };
  ;
  return (
    <>
      <Carousel
        arrows={false}
        swipeable={false}
        itemClass="carouselItem"
        partialVisible={false}
        autoPlay={true}
        rewind={true}
        // infinite={true}
        responsive={responsive} className="footer-carousel">
        {
          images.map((item, index) => {
            return (
              <div className="footer-carousel-item" key={item.text + index} onClick={() => {
                handleClick(item.url);
              }}
              >
                <div className="footer-carousel-item-imagewrap">
                  <img src={item.src} alt={item.alt} />
                </div>
                <p>{item.text}</p>
              </div>
            )
          })
        }

      </Carousel>
    </>
  );
}
