import React from "react";
import "./complain.scss";

const compensation = [
  {
    text: "DCDRC",
    desc: "When claim amount is upto ₹ 50 Lakhs",
  },
  {
    text: "SCDRC",
    desc: "When claim amount is higher than ₹ 50 Lakhs and upto ₹ 2 Crore.",
  },
  {
    text: "NCDRC",
    desc: "When claim amount is higher than ₹ 2 Crore",
  },
];

const Compensation = ({ text, desc }: { text: string; desc: string }) => {
  return (
    <div className="d-flex gap-3 mb-3 ">
      <div>
        <div
          className="bg-warning d-flex justify-content-center  align-items-center "
          style={{ minHeight: "3.5rem", minWidth: "3.5rem" }}
        >
          <img
            src="/complaints/complaintsCheck.svg"
            alt=""
            style={{ height: "2.4rem", width: "2.4rem" }}
          />
        </div>
      </div>
      <div>
        <h4 className="text-white  fw-bold">{text}</h4>
        <p className="text-white">{desc}</p>
      </div>
    </div>
  );
};

export default function Filecomplain() {
  return (
    <section className=" row complain-section-bg shadow-sm  m-0">
      <div className="col-xl-7 position-relative  complain-left order-2 order-lg-1 ">
        <div className="complain-bg w-100 h-100  d-none d-lg-block">
          <img
            className="complain-bg-img w-100 h-100"
            src="/complaints/complaintsbg.png"
          />
        </div>
        {/* <img
          className="position-absolute top-50 start-50 translate-middle d-none  d-md-block"
          src="/complaints/circles.png"
          alt=""
        /> */}
        <p className="position-absolute  element-value d-block d-xl-none">
          commission
        </p>

        <div className=" d-flex flex-column gap-3 py-5 ">
          <h3 className="text-white text-capitalize">
            File Your Complaint Under
          </h3>
          <div className="">
            {compensation.map((item, index) => {
              return (
                <Compensation key={index} text={item.text} desc={item.desc} />
              );
            })}
          </div>
        </div>
      </div>
      <div className="col-xl-5 bg-white order-1">
        <h1>Graph</h1>
      </div>
    </section>
  );
}
