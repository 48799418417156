export default function NewsPressRelease() {

    const newsCardDetails=
        {
            duration:"12 hours ago",
            image:"/images/news1.jpg",
            details:`Artificial Intelligence (AI) integration in e-Jagriti will help in
                            reducing the pendency
                            of
                            consumer cases in the Consumer Commissions: Secretary, Department of Consumer Affairs,
                            GoI`
        }



    const dataPressRelease = [
        {
            icon:"/images/new2.jpg",
            source: "Press Information Bureau",
            duration:"12 hours ago",
            details: `Contrary to popular belief, Lorem Ipsum is not simply random text. It
                        has roots in a piece of
                        classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock,
                        a Latin professor at Hampden-Sydney College in Virginia, looked up one of`
        },
        {
            icon:"/images/news3.jpg",
            source: "Press Information Bureau",
            duration:"12 hours ago",
            details: `Contrary to popular belief, Lorem Ipsum is not simply random text. It
                        has roots in a piece of
                        classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock,
                        a Latin professor at Hampden-Sydney College in Virginia, looked up one of`
        },
        {
            icon:"/images/news4.jpg",
            source: "Press Information Bureau",
            duration:"12 hours ago",
            details: `Contrary to popular belief, Lorem Ipsum is not simply random text. It
                        has roots in a piece of
                        classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock,
                        a Latin professor at Hampden-Sydney College in Virginia, looked up one of`
        }
    ]



    const PressReleaseTemplate = ({imageUrl,source,duration,details,key}:{imageUrl:string,source:string,duration:string,details:string,key:any}) => {
        return (
            <div className="d-flex flex-column gap-2 bg-press-release px-2 py-3" key={key}>
                <div className="d-flex flex-row justify-content-between fw-semibold text-mute">
                    <small>{source}</small>
                    <small>{duration}</small>
                </div>
                <div className="d-flex flex-row gap-3 ">

                    <div className="size-56px">
                        <img className="object-fit-cover w-100 h-100" alt={source} src={imageUrl}/>
                    </div>
                    <div>
                        <p className="ellipsis-2 ">{details}</p>
                    </div>

                </div>

            </div>
        )
    }



    return (
        <div className="news-spotlight-subgrid">
            <h5 className="fw-bold">Stay Informed, Stay Empowered!</h5>
            <h2 className="fw-bold">News / <span className="theme-secondary">Press Release</span></h2>
            <div className="news-press-release-grid">
                <div className="card-width-responsive d-flex flex-column bg-white rounded">
                    <div className="content-card-size-responsive rounded-top">
                        <img className="object-fit-cover w-100 h-100" alt={"Press Release"} src={newsCardDetails.image}/>
                    </div>
                    <div className="p-2 d-flex flex-column gap-2">
                    <div className="d-flex flex-row-reverse justify-content-between"><small>{newsCardDetails.duration}</small>
                        </div>
                        <p className="ellipsis-3 ">{newsCardDetails.details}</p>
                    </div>
                </div>
                <div className="d-flex flex-column gap-1 justify-content-between">
                    {
                        dataPressRelease.map((item,index)=>(
                            <PressReleaseTemplate key={index} source={item.source} imageUrl={item.icon} duration={item.duration} details={item.details}/>
                        ))
                    }
                </div>

            </div>
            <div className="d-flex flex-row justify-content-center">
                <button className="btn btn-light">View all</button>
            </div>
        </div>
    )
}