import React, { useEffect, useState } from "react";
import "./ourvision.scss";
const animatedImage1 = "/topbar/animatedsection1.png";
const animatedImage2 = "/topbar/animatedsection2.png";
const animatedImage3 = "/topbar/animatedsection3.png";
const animatedImage4 = "/topbar/animatedsection4.png";
const animatedImage5 = "/topbar/animatedsection5.png";

const images = [
  {
    id: 1,
    image: animatedImage1,
    heading: (
      <p className="text-white fs-1  fw-bold    ">
        OUR <span className="animate-heading ">VISION</span>
      </p>
    ),
    description:
      "eJagriti aims to achieve exemplary egovernance characterized by efficiency, transparency, accessibility thereby eliminating long pendency of disputes.",
  },
  {
    id: 2,
    image: animatedImage5,
    heading: (
      <p className="text-white text-white fs-1  fw-bold   ">
        <span className="animate-heading ">Speak </span> and
        <span className="animate-heading "> file </span> your
        <span className="animate-heading "> Complaint</span>
      </p>
    ),
    description:
      "Report your concerns and grievances in a straightforward and  hassle-free manner, ensuring your voice is heard by the authorities.",
  },
  {
    id: 3,
    image: animatedImage3,
    heading: (
      <p className="text-white text-white fs-1  fw-bold   ">
        <span className="animate-heading ">Virtual </span>hearing in
        <span className="animate-heading "> commission </span>proceedings
      </p>
    ),
    description:
      "Streamlining consumer complaints with Virtual Court integration for efficient and swift resolution.",
  },
  {
    id: 4,
    image: animatedImage4,
    heading: (
      <p className="text-white  fs-1  fw-bold   ">
        <span className="animate-heading ">SEAMLESS </span>COMPLAINT FILING ALL
        OVER <span className="animate-heading ">INDIA </span>
      </p>
    ),
    description:
      "Easy-to-use online complaint submission platform for citizens to report issues and concerns to local government",
  },
];

const caseCountList = [
  {
    imageUrl: "/topbar/casecount3.svg",
    caseCount: "26L+",
    caseType: "Filled Cases",
  },
  {
    imageUrl: "/topbar/casecount2.svg",
    caseCount: "20L+",
    caseType: "Disposed Cases",
  },
  {
    imageUrl: "/topbar/casecount1.svg",
    caseCount: "6L+",
    caseType: "Progress Cases",
  },
];

const CaseCount = ({
  imageUrl,
  caseCount,
  caseType,
}: {
  imageUrl: string;
  caseCount: string;
  caseType: string;
}) => {
  return (
    <div className="d-flex gap-2  align-items-center">
      <div>
        <img src={imageUrl} alt={caseType} className=" " />
      </div>
      <div>
        <p className="text-dark fs-1 fw-semibold text-decoration-underline  m-0">
          {caseCount}
        </p>
        <p className="text-dark fs-5 fw-bold  m-0">{caseType}</p>
      </div>
    </div>
  );
};

export default function Ourvision() {
  const [imageFallBack, setImageFallBack] = useState<boolean>(false);
  const [selectedImage, setSelectedImage] = useState<any>(images);
  const [animationPause, setAnimationPause] = useState(false);
  const delay = 5000;
  const [count, setCount] = useState(0);

  const AnimatedSectionTemplate = () => {
    const [imageFallBack, setImageFallBack] = React.useState(false);
    const [animationPause, setAnimationPause] = React.useState(false);

    const ImageTemplate = (image?: string) => {
      if (image) {
        return (
          <>
            {!imageFallBack && (
              <img
                alt=""
                // className="ratio ratio-1x1"
                className="w-100 h-100 object-fit-fill"
                src={image}
                onError={() => {
                  setImageFallBack(true);
                }}
              />
            )}
          </>
        );
      }
      return null;
    };

    const image = selectedImage?.image;

    return (
      <div
        key={selectedImage!.id}
        className={`h-100 animate__animated animate__fadeIn ${
          animationPause ? "animate__pause" : ""
        }`}
        style={{ "--animate-duration": "2s" } as React.CSSProperties}
        onMouseOver={() => setAnimationPause(true)}
        onMouseLeave={() => setAnimationPause(false)}
      >
        {ImageTemplate(image)}
      </div>
    );
  };

  useEffect(() => {
    if (images) {
      const max = images.length - 1;
      const interval = setTimeout(() => {
        if (!animationPause) {
          setCount((prevState) => {
            if (prevState > max - 1) {
              return 0;
            } else {
              return prevState + 1;
            }
          });
        }
      }, delay);

      return () => {
        clearInterval(interval);
      };
    }
  }, [count, images, animationPause]);
  useEffect(() => {
    if (images) setSelectedImage(images[count]);
  }, [count]);

  return (
    <>
      <section id="" className="row bg-warning   m-0 ourvision-section ">
        <div className=" col-xl-7  my-5 pl-2">
          <div
            className="d-flex flex-column justify-content-between "
            style={{ height: "100%" }}
          >
            <div className="space-y-4  animate-text ">
              <h1>{selectedImage.heading}</h1>
              <p className="text-white fs-4 fw-semibold">
                {selectedImage.description}
              </p>
            </div>
            <div className="d-flex flex-wrap gap-3">
              {caseCountList.map((item, index) => {
                return (
                  <CaseCount
                    key={index}
                    imageUrl={item.imageUrl}
                    caseCount={item.caseCount}
                    caseType={item.caseType}
                  />
                );
              })}
            </div>
          </div>
        </div>
        <div className="col-xs-6 col-md-5 m-0 p-0 d-none  d-xl-block">
          {AnimatedSectionTemplate()}
        </div>
      </section>
    </>
  );
}
