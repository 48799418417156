import NewsPressRelease from "./news-pressrelease";
import "./news-and-spolight.scss"
import CarouselSpotlight from "./carousel.spotlight";
export default function NewsAndSpotlight(){
    return (
        <section className="news-spotlight-section section-padding">
            <div className="news-spotlight-grid">
                <div className="news-spotlight-subgrid">
                    <h5>Stay Informed, Stay Empowered!</h5>
                    <h2 className="fw-bold">Spotlight / <span className="theme-secondary">Highlight</span></h2>
                    <CarouselSpotlight/>
                </div>
                <NewsPressRelease/>
            </div>
        </section>
    )
}