import { GoSearch } from "react-icons/go";
import "./menuheader.scss";
import { MdAccessibility } from "react-icons/md";
const MenuHeader = () => {
  const navList = [
    {
      component: <p className=" my-0">Screen Reader</p>,
      onClick: () => {},
    },
    {
      component: <p className=" my-0">Skip to Main Content</p>,
      onClick: () => {},
    },
    {
      component: <p className=" my-0">A+</p>,
      onClick: () => {},
    },
    {
      component: <p className=" my-0">A-</p>,
      onClick: () => {},
    },
    {
      component: <p className=" my-0">A</p>,
      onClick: () => {},
    },
    {
      component: (
        <img
          className=""
          src="/header/e-jagritidarklightmodesvg.svg"
          alt="High/Low Contrast Mode"
        />
      ),
      onClick: () => {},
    },
    {
      component: (
        <img
          className="language-icon"
          src="/header/language.svg"
          alt="Change Language"
        />
      ),
      onClick: () => {},
    },
    {
      component: <GoSearch className="" />,
      onClick: () => {},
    },
  ];
  const navList2 = [
    {
      component: (
        <img
          className="language-icon"
          src="/header/language.svg"
          alt="Change Language"
        />
      ),
      onClick: () => {},
    },
    {
      component: (
        <div className="dropdown">
          <button
            className="btn accessibility-button  "
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <MdAccessibility className="accessibility-icon"/>
          </button>
          <ul className="dropdown-menu">
            <li>
              <a className="dropdown-item" href="#">
                A+
              </a>
            </li>
            <li>
              <a className="dropdown-item" href="#">
                A-
              </a>
            </li>
            <li>
              <a className="dropdown-item" href="#">
               A
              </a>
            </li>
          </ul>
        </div>
      ),
      onClick: () => {},
    },
  ];

  return (
    <nav className="my-auto">
      <ul className="d-flex d-md-none menu-header-container my-0">
        {navList2.map((item, index) => (
          <li key={index} onClick={item.onClick}>
            {item.component}
          </li>
        ))}
      </ul>
      <ul className="d-none d-md-flex menu-header-container my-0">
        {navList.map((item, index) => (
          <li key={index} onClick={item.onClick}>
            {item.component}
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default MenuHeader;
