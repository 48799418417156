// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.93.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.93.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.india-map-absolute-div {
  position: absolute;
  bottom: 10%;
  right: 1dvw;
}
.india-map-absolute-div > div > button {
  font-size: 0.8rem;
}`, "",{"version":3,"sources":["webpack://./src/LandingPage/Trends/trends.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,WAAA;EACA,WAAA;AACF;AACE;EACE,iBAAA;AACJ","sourcesContent":[".india-map-absolute-div{\n  position: absolute;\n  bottom: 10%;\n  right: 1dvw;\n  //backdrop-filter: blur(2px);\n  > div > button {\n    font-size: 0.8rem;\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
