import React, { useRef } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./testimonials.scss";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import { TiStarFullOutline } from "react-icons/ti";
const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 2,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 2,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const data = [1, 2, 3, 4, 5, 6];

export default function Testimonials() {
  const carouselRef: any = useRef(null);

  const StarRating = ({ rating }: { rating: any }) => {
    const totalStars = 5;
    const fullStars = Math.floor(rating);
    const hasHalfStar = rating % 1 !== 0;

    return (
      <>
        <div className="bg-light d-flex align-items-center justify-content-center position-absolute rating-box rounded-pill py-2 ">
          {Array.from({ length: totalStars }, (v, i) => (
            <TiStarFullOutline key={i} className="" />
          ))}
        </div>

        <div className="p-2 ratings-circle d-flex align-items-center justify-content-center bg-primary rounded-circle text-white text-sm font-weight-bold text-center position-absolute  ">
          {rating}
        </div>
      </>
    );
  };

  const handleNext = () => {
    if (carouselRef.current) {
      carouselRef.current.next();
    }
  };

  const handlePrev = () => {
    if (carouselRef.current) {
      carouselRef.current.previous();
    }
  };

  return (
    <section className="h-100 testimonial-sections    position-relative complain-left">
      <div className="complain-bg w-100 h-100   ">
        <img src="/testimonials/newbg.png" alt="" />
      </div>
      <div>
        <p className="text-black  text-center"> Our Testimonials</p>
        <h3 className="text-center">
          <span className="text-warning">Citizens</span> Share Their
          <span className="text-warning">Success</span> Stories
        </h3>
      </div>
      <div className="my-5">
        <Carousel arrows={false} ref={carouselRef} responsive={responsive}>
          {data.map((i, item) => (
            <div className="bg-white bg-opacity-25 rounded border border-light  p-3 md-p-4 d-flex gap-4 position-relative mr-2">
              <img
                className="position-absolute bottom-0 end-0 mb-4 me-4 pointer-events-none"
                src="/testimonials/testimonials.svg"
                alt=""
              />
              <div
                className="bg-white rounded flex-shrink-0 p-2"
                style={{ flexBasis: "40%" }}
              >
                <div>
                  <img
                    className="w-100"
                    src="/testimonials/Rectangle 2470.png"
                    alt=""
                  />
                </div>
                <div className="mt-2 ms-3">
                  <p className="text-dark text-lg font-medium mb-0">Shruti</p>
                  <p className="text-secondary text-sm">
                    <small>Noida, Uttar Pradesh</small>
                  </p>
                </div>
              </div>
              <div className="flex-grow-1 ">
                <div className="d-flex  ">
                  <div>
                    <img src="/testimonials/testimonials1.svg" alt="" />
                  </div>
                  <StarRating rating={3.5} />
                </div>
                <div className="text-dark text-lg font-normal mt-3">
                  It is a long established fact that a reader will be distracted
                  by the readable content of this when looking at it.
                </div>
              </div>
            </div>
          ))}
        </Carousel>
      </div>

      <div className="d-flex align-item-center justify-content-center my-4">
        <div
          className="carousel-button arrow-left"
          onClick={() => {
            handlePrev();
          }}
        >
          <IoIosArrowBack className="arrow-fill " />
        </div>
        <div
          className="carousel-button arrow-right"
          onClick={() => {
            handleNext();
          }}
        >
          <IoIosArrowForward className="arrow-fill " />
        </div>
      </div>
    </section>
  );
}
