import React, { useRef, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./video.scss";
import { FaPlay } from "react-icons/fa";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const debounce = (func: (...args: any[]) => void, delay: number) => {
  let timeoutId: ReturnType<typeof setTimeout>;
  return (...args: any[]) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

export default function Videos() {
  const videoRefs = useRef<(HTMLVideoElement | null)[]>([]);
  const carouselRef: any = useRef<Carousel>(null);
  const [isPlaying, setIsPlaying] = useState<{ [key: number]: boolean }>({});

  const playPause = debounce((index: number) => {
    const video = videoRefs.current[index];
    if (video) {
      if (video.paused) {
        video
          .play()
          .then(() => setIsPlaying((prev) => ({ ...prev, [index]: true })))
          .catch((error) => console.error(error));
      } else {
        video.pause();
        setIsPlaying((prev) => ({ ...prev, [index]: false }));
      }
    }
  }, 300);

  const handleNext = () => {
    if (carouselRef.current) {
      carouselRef.current.next();
    }
  };

  const handlePrev = () => {
    if (carouselRef.current) {
      carouselRef.current.previous();
    }
  };

  const arr = [1, 2, 3, 4, 5];

  return (
    <section className="container section-padding">
      <div className="position-relative">
        <div>
          <p className="heading-color text-center">Videos</p>
          <h3 className="text-center heading-color">
            Your Knowledge
            <span className="text-warning"> Your </span> Power
          </h3>
        </div>
        <div className="my-5">
          <Carousel arrows={false} ref={carouselRef} responsive={responsive}>
            {arr.map((item, i) => (
              <div key={i} className="position-relative card-video rounded">
                <div className="ratio video-ratio rounded">
                  <video
                    ref={(el) => (videoRefs.current[i] = el)}
                    className="rounded"
                    controls
                  >
                    <source src="movie.mp4" type="video/mp4" />
                  </video>
                </div>
                <br />
                <div
                  className="rounded-circle play-btn"
                  onClick={() => playPause(i)}
                >
                  <FaPlay className="play-icon" />
                </div>
                <div className="px-1">
                  <p className="fs-5 fw-bolder">E-Daakhil (Animated)</p>
                  <p className="fs-6">
                    Amet minim mollit non deserunt ullamco est sit aliqua dolor
                    do amet sint.
                  </p>
                </div>
              </div>
            ))}
          </Carousel>
        </div>
        <div
          className="carousel-button arrow-left btn-round rounded-circle left-btn"
          onClick={handlePrev}
        >
          <IoIosArrowBack />
        </div>
        <div
          className="carousel-button arrow-right btn-round rounded-circle right-btn"
          onClick={handleNext}
        >
          <IoIosArrowForward />
        </div>
      </div>
    </section>
  );
}
