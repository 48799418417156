// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.93.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.93.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.language-icon {
  width: 19px;
  height: 19px;
}

.menu-header-container {
  gap: 1rem;
  list-style: none;
  align-items: center;
}

.accessibility-button {
  color: white;
}

.accessibility-icon {
  width: 19px;
  height: 19px;
  fill: white;
}`, "",{"version":3,"sources":["webpack://./src/LandingPage/Header/menuheader.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,YAAA;AACJ;;AACA;EACI,SAAA;EACA,gBAAA;EACA,mBAAA;AAEJ;;AAAA;EACI,YAAA;AAGJ;;AADA;EACI,WAAA;EACA,YAAA;EACA,WAAA;AAIJ","sourcesContent":[".language-icon{\n    width: 19px;\n    height: 19px;\n}\n.menu-header-container{\n    gap: 1rem;\n    list-style: none;\n    align-items: center;\n}\n.accessibility-button{\n    color: white;\n}\n.accessibility-icon{\n    width: 19px;\n    height: 19px;\n    fill: white;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
