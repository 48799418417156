import Header from "../src/LandingPage/Header/Header";
import Trends from "./LandingPage/Trends/trends";
import NewsAndSpotlight from "./LandingPage/NewsAndSpotlight/newsandspotlight";
import SearchCases from "./LandingPage/SearchCases/searchcases";
import Footer from "./LandingPage/Footer/footer";
import Carousel from "./LandingPage/Footer/_Footer/Carousel/carasoule";
import Onboarding from "./LandingPage/Onboarding/Onboarding";
import Filecomplain from "./LandingPage/filecomplains/filecomplain";
import Ourvision from "./LandingPage/ourvision/ourvision";
import Testimonials from "./LandingPage/testimonials/testimonials";
import Videos from "./LandingPage/videos/videos";
import Statics from "./LandingPage/Statics/statics";

function App() {
  return (
    <div className="App">
      <Header />
      <Ourvision />
      <Onboarding />
      <Filecomplain />
      <SearchCases/>
      <NewsAndSpotlight />
      <Trends />
      <Statics />
      <Videos />
      <Testimonials />
      <Carousel/>
      <Footer/>
    </div>
  );
}

export default App;
