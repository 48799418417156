import React from 'react'
import "./footer.scss"

const Footer = () => {
    return (
        <footer className='container-fluid p-0'>
            <div className="container-fluid footer-section-inner px-2 ">
                <div className="row gap-2 gap-md-5 footer-section-inner-row">
                    <div className="col-md p-3">
                        <div className='d-flex d-flex-wrap justify-content-evenly'>
                            <img src="/images/NIC.png" alt="NIC" className="mb-2 footer-section-left-image" />
                            <img
                                src="/images/digi.png"
                                alt="Digital India Logo"
                                className="mb-2 footer-section-left-image"
                            />
                        </div>
                        <p className="fw-bold mb-2 my-4 footer-section-left-text">
                            © E-Jagriti Platform Is Developed, Designed And Maintained By{" "}
                            <span className="fw-normal footer-section-left-text-highlight ">
                                National Informatics Centre,Ministry Of Electronics &
                                Information Technology Government Of India.
                            </span>
                        </p>
                    </div>
                    <div className="col-md col-sm-6 p-3">
                        <div className='text-center footer-section-mid-text-t'>
                            <p>Other Links</p>
                        </div>
                        <div className='d-flex d-flex-wrap justify-content-between fw-bold text-capitalize footer-section-mid-text-b'>
                            <p><a href='#'>WebSite Policies</a></p>
                            <p><a href='#'>Disclaimer</a></p>
                        </div>
                        <div className='d-flex d-flex-wrap justify-content-between fw-bold text-capitalize footer-section-mid-text-b'>
                            <p><a href='#'>Help</a></p>
                            <p><a href='#'>Terms & Conditions</a></p>
                        </div>
                        <div className='d-flex d-flex-wrap justify-content-between fw-bold text-capitalize footer-section-mid-text-b'>
                            <p><a href='#'>FAQ</a></p>
                            <p><a href='#'>Feedback</a></p>
                        </div>
                    </div>
                    <div className="col-md p-3">
                        <div className='text-center footer-section-mid-text-t'>
                            <p className=''>Follow Us</p>
                        </div>
                        <div className='text-center fw-bold text-capitalize footer-section-right-text-b'>
                            <p>Stay connect with us through out social media platform</p>
                        </div>
                        <div className='d-flex d-flex-wrap justify-content-around'>
                            <a href="#">
                                <img
                                    src="/images/facebook.png"
                                    alt="Facebook"
                                    className="footer-section-right-icon"
                                />
                            </a>
                            <a href="#">
                                <img
                                    src="/images/youtube.png"
                                    alt="Youtube"
                                    className="footer-section-right-icon"
                                />
                            </a>
                            <a href="#">
                                <img
                                    src="/images/instagram.png"
                                    alt="Instagram"
                                    className="footer-section-right-icon"
                                />
                            </a>
                            <a href="#">
                                <img
                                    src="/images/twitter.png"
                                    alt="Twitter"
                                    className="footer-section-right-icon"
                                />
                            </a>


                        </div>
                    </div>
                </div>
            </div>

        </footer>
    )
}

export default Footer