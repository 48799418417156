import MenuHeader from "./MenuHeader";
import "./header.scss";
import { GoSearch } from "react-icons/go";
import { FaRegUser } from "react-icons/fa";
import { RxHamburgerMenu } from "react-icons/rx";

const ashokSthambLogo = "/header/ejagriti-ashok-sthamb.svg";

const Header = () => {
  const menuList = [
    {
      component: <p className="my-0"> About E-jagriti </p>,
      onClick: () => {},
    },
    {
      component: <p className=" my-0"> Case History/Status </p>,
      onClick: () => {},
    },
    {
      component: <p className=" my-0"> Judgement </p>,
      onClick: () => {},
    },
    {
      component: <p className=" my-0"> Display Board </p>,
      onClick: () => {},
    },
    {
      component: <p className=" my-0"> Causelist </p>,
      onClick: () => {},
    },
    {
      component: <p className=" my-0"> Reports </p>,
      onClick: () => {},
    },
    {
      component: <p className=" my-0"> Tutorials </p>,
      onClick: () => {},
    },
    {
      component: <button className="login-button"> Log in </button>,
      onClick: () => {},
    },
    {
      component: <button className="login-button"> Sign up </button>,
      onClick: () => {},
    },
  ];
  const menuList2 = [
    {
      component: <GoSearch className="" />,
      onClick: () => {},
    },
    {
      component: (
        <div className="dropdown">
          <button
            className="btn "
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <FaRegUser className="" />
          </button>
          <ul className="dropdown-menu">
            <li>
              <a className="dropdown-item" href="#">
                Log In
              </a>
            </li>
            <li>
              <a className="dropdown-item" href="#">
              Sign Up
              </a>
            </li>
          </ul>
        </div>
      ),
      onClick: () => {},
    },
    {
      component: (
        <>
          <p className="d-inline-flex  my-auto p-0">
            <button
              className="btn p-0"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseExample"
              aria-expanded="false"
              aria-controls="collapseExample"
            >
              <RxHamburgerMenu />
            </button>
          </p>
        </>
      ),
      onClick: () => {},
    },
  ];
  return (
    <header>
      <div className="d-flex header-container justify-content-md-between">
        <div className="d-flex align-items-center gap-3">
          <div>
            <img src={ashokSthambLogo} alt="Ashok Sthamb" />
          </div>
          <p className="header-text my-0">
            Department of Consumer Affairs, Ministry of Consumer Affairs, Food &
            Public Distribution | GOVERNMENT OF INDIA
          </p>
        </div>
        <div className="d-flex align-items-center">
          <MenuHeader />
        </div>
      </div>
      <div className="d-flex justify-content-between align-items-center header-container-bottom">
        <div>
          <img
            className="e-jagriti-logo-header"
            src="/header/ejagriti.png"
            alt="E-Jagriti"
          />
        </div>
        <div>
          <ul className="d-none d-xl-flex gap-4  menu-bottom-list my-0 align-items-center">
            {menuList.map((item, index) => (
              <li key={index} onClick={item.onClick}>
                {item.component}
              </li>
            ))}
          </ul>
          <ul className="d-flex d-xl-none gap-4  menu-bottom-list my-0 align-items-center">
            {menuList2.map((item, index) => (
              <li key={index} onClick={item.onClick}>
                {item.component}
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="collapse" id="collapseExample">
        <div className="card card-body">
        <ul className="d-flex d-xl-none flex-column p-0 menu-bottom-list my-0 align-items-center">
            {menuList.slice(0,7).map((item, index) => (
              <li className="ham-burger-list-item" key={index} onClick={item.onClick}>
                {item.component}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </header>
  );
};

export default Header;
